<template>
  <div>
    <CRow>
      <CCol col="12">
           <CCard class="cards">
          <form id="updateOrder" @submit="updateProductPrice" >
          <CCardHeader>
            <CCol col="6" class="styleHeader">{{$t('message.updateProductPrice')}} </CCol>
          </CCardHeader>
          <CCardBody>
            <CRow>
                 <CInput
                        type='text'
                        id='name'
                         :label="$t('message.customerName')"
                        disabled
                        :value='customerName'
                      />
            </CRow>
            <CRow class="mt-3">
              <CCol col="6">
                <h3 class="mb-3">{{$t('message.products')}}</h3>
                <div class="line"></div>
              </CCol>
            </CRow>
             <CRow class="mt-2">
              <table class="table">
                <thead>
                  <tr>
                  <th>  {{$t('message.product')}}<span class='star'>*</span> </th>
                  <th>  {{$t('message.price')}}<span class='star'>*</span></th>
                  <th > {{$t('message.updateProductPrice')}}  </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(product, index) in products"
                    :key="index"
                  >
                  <td class="col-md-4">
                    {{product.name}}
                   </td>
                     <td >
                      <CInput
                        type='text'
                        id='price'
                        disabled
                        :value='product.price'
                      />
                    </td>
                      <td >
                      <CInput
                       :id="product.id"
                         type='number'
                         :value="product.custom_price"
                        v-model='product.custom_price'
                        step="0.01" title="Currency" pattern="^\d+(?:\.\d{1,2})?$"
                        @chang='pricechanged'
                        />
                    </td>
                  </tr>
                </tbody>
              </table>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton type='submit' size='sm' color='primary'>
            <CIcon name='cil-check-circle'/> {{$t('message.save')}}</CButton>
              &emsp;
            <CButton type="button" size="sm" color="btn btn-primary" @click="goBack">
            <CIcon name="cil-ban"/> {{ $t('message.back') }} </CButton>
          </CCardFooter>
          </form>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: 'UpdateProductPrice',
  data: function () {
    return {
      products: [],
      salesPrice: '',
      sendProducts: [],
      customerName: '',
      errors: []
    }
  },
  created () {
    this.$http
      .get(`${this.$hostUrl}customers/${this.$route.params.id}`).then((response) => {
        this.customerName = response.data.data.customer.name
      })

    const products = []
    this.$http
      .get(`${this.$hostUrl}products?customer_id=${this.$route.params.id}`)
      .then((response) => {
        console.log(response.data.data)
        $.each(response.data.data, function (key, value) {
          products.push({ id: value.id, name: value.name, price: value.price, custom_price: value.custom_price })
        })
        this.products = products
      })
  },
  methods: {

    async updateProductPrice (e) {
      e.preventDefault()
      const formData = new FormData()
      formData.append('customer_id', this.$route.params.id)
      this.sendProducts = []
      for (let i = 0; i <= this.products.length - 1; i++) {
        if (this.products[i].custom_price) {
          this.sendProducts.push({ id: this.products[i].id, sales_price: this.products[i].custom_price })
        }
      }
      formData.append('products', JSON.stringify(this.sendProducts))
      this.errors = await this.postRequest(formData, `${this.$hostUrl}change-sales-price`, '/customers')
      location.reload()
    },
    goBack () {
      this.productsOpened ? this.$router.go(-1) : this.$router.push({ path: '/customers' })
    }
  }
}

</script>

<style>
.order{
  background-color: #9daec5;
  color: #fff;
  padding: 15px;
  border-radius: 20%;
  font-size: 30px !important;
}
.add{
   background-color: #2a3647 !important;
  color: #fff !important;
  font-size: 15px !important;
}
</style>
